import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { TRANSITION_TIMING_FUNCTION } from '@/app/consts/app';

export default (): Record<string, SxProps<Theme>> => ({
  toggleContainer: {
    color: COLOURS.HUE_COCONUT.HUE_153,
    cursor: 'pointer',
    transition: `all 250ms ${TRANSITION_TIMING_FUNCTION}`,

    '&:hover': {
      color: COLOURS.HUE_COCONUT.HUE_195,
    },
  },
});
