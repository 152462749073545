import { COLOURS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import React, { useEffect, useRef } from 'react';
import { getRoundedNumber } from '@/app/utils/app';
import { useAppSelector } from '@/hooks/useAppSelector';
import { BAR_HEIGHT, GRAPH_MARGINS, MONTH_GAP, X_AXIS_TEXT_OFFSET, Y_AXIS_TICKS } from './consts';
import * as d3 from './d3';
import selector from './selector';
import { getMaxBarHeight, getMonthWidth, getPlanFactBarPath } from './utils';

const containerStyles: SxProps<Theme> = {
  width: '100%',
};

const AnalyticsPlanFactClientArticlesGraph = () => {
  const { items, selectedMonths, amountFormatted } = useAppSelector(selector);

  const rootRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    rootRef.current!.innerHTML = '';

    if (!items) {
      return;
    }

    const groupNames = d3.map(items, (item) => item.name);
    const offsetLeft = GRAPH_MARGINS.left;
    const maxBarHeight = getMaxBarHeight(items);
    const monthWidth = getMonthWidth(selectedMonths);
    const graphContentWidth = (MONTH_GAP + monthWidth) * selectedMonths.length;

    // create graph field
    const svg = d3
      .select(rootRef.current!)
      .append('svg')
      .attr('height', BAR_HEIGHT + GRAPH_MARGINS.top + GRAPH_MARGINS.bottom)
      .attr('width', graphContentWidth + GRAPH_MARGINS.left + GRAPH_MARGINS.right);

    // create scales
    const xScale = d3.scaleBand().domain(groupNames).range([0, graphContentWidth]);

    const xMonthSubScale = d3.scaleBand().domain(['budget', 'fact']).range([0, xScale.bandwidth()]);

    const yScale = d3.scaleLinear().domain([0, maxBarHeight]).range([BAR_HEIGHT, 0]).nice();

    // create axes
    const xAxis = d3.axisBottom(xScale).ticks(selectedMonths.length);
    const yAxis = d3.axisLeft(yScale).ticks(Y_AXIS_TICKS);

    // style axes
    const xAxisElement = svg
      .append('g')
      .call(xAxis)
      .attr('transform', `translate(${offsetLeft}, ${BAR_HEIGHT + GRAPH_MARGINS.top + X_AXIS_TEXT_OFFSET})`);

    xAxisElement.selectAll('path, line').remove();

    xAxisElement
      .selectAll('text')
      .attr('font-size', '14px')
      .html((_, idx) => {
        const bar = items[idx];

        return `
        <tspan x="0" dy="0" fill="${COLOURS.HUE_COCONUT.HUE_153}" font-size="12px">${bar.name}</tspan>
        <tspan x="0" dy="20" fill="${
          COLOURS.HUE_COCONUT.HUE_195
        }" font-size="12px" font-family="IBM Plex Mono,monospace" font-weight="600">${amountFormatted(
          bar.budget
        )}</tspan>
        <tspan dx="10" dy="2px" fill="${COLOURS.HUE_COCONUT.HUE_50}" font-size="16px" font-weight="600">|</tspan>
        <tspan dx="10" dy="-2px" fill="${
          COLOURS.HUE_COCONUT.HUE_195
        }" font-size="12px" font-family="IBM Plex Mono,monospace" font-weight="600">${amountFormatted(bar.fact)}</tspan>
        `;
      });

    const yAxisElement = svg
      .append('g')
      .call(yAxis)
      .attr('transform', `translate(${GRAPH_MARGINS.left}, ${GRAPH_MARGINS.top})`);

    yAxisElement
      .selectAll('line')
      .attr('stroke', COLOURS.HUE_COCONUT.HUE_50)
      .attr('x1', graphContentWidth)
      .attr('x2', -GRAPH_MARGINS.left);

    yAxisElement.select(':nth-child(1) line').attr('stroke', COLOURS.HUE_COCONUT.HUE_100);

    yAxisElement
      .selectAll('text')
      .attr('y', -8)
      .attr('font-size', '10px')
      .attr('user-select', 'none')
      .attr('fill', COLOURS.HUE_COCONUT.HUE_153)
      .text((num) => getRoundedNumber(num as number));

    yAxisElement.selectAll('path').remove();

    // create empty month elements
    svg
      .selectAll('.month')
      .data(d3.group(items, (d) => d.name))
      .join('g')
      .classed('month', true)
      .attr('transform', (_) => `translate(${offsetLeft}, ${GRAPH_MARGINS.top})`);

    // create Budget (plan) bars
    svg
      .selectAll('.month')
      .selectAll('path.budget')
      .data(([_, groupData]: any) => groupData)
      // используем path вместо rect, чтобы нарисовать закругленные углы
      .join('path')
      .classed('budget', true)
      .style('fill', COLOURS.HUE_GUAVA.HUE_120)
      .attr('d', (groupData: any) => {
        const barValue = groupData.budget;
        const xScaleOffset = xScale(groupData.name) ?? 0;
        const yScaleOffset = yScale(barValue) ?? 0;
        const xMonthSubScaleBandwidth = xMonthSubScale.bandwidth();

        return getPlanFactBarPath('budget', barValue, xScaleOffset, yScaleOffset, xMonthSubScaleBandwidth);
      });

    // create Fact bars
    svg
      .selectAll('.month')
      .selectAll('path.fact')
      .data(([_, groupData]: any) => groupData)
      // используем path вместо rect, чтобы нарисовать закругленные углы
      .join('path')
      .classed('fact', true)
      .style('fill', (d: any) => (d.fact > d.budget ? COLOURS.HUE_STRAWBERRY.HUE_100 : COLOURS.HUE_CUCUMBER.HUE_120))
      .attr('d', (groupData: any) => {
        const barValue = groupData.fact;
        const xScaleOffset = xScale(groupData.name) ?? 0;
        const yScaleOffset = yScale(barValue) ?? 0;
        const xMonthSubScaleBandwidth = xMonthSubScale.bandwidth();

        return getPlanFactBarPath('fact', barValue, xScaleOffset, yScaleOffset, xMonthSubScaleBandwidth);
      });
  }, [items]);

  return <Box ref={rootRef} sx={containerStyles} />;
};

export default AnalyticsPlanFactClientArticlesGraph;
