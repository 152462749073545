import Autocomplete from '@core-ui/autocomplete';
import DatePicker, { RangePickerProps } from '@core-ui/datepicker';
import { useFilter } from '@core-ui/hooks';
import { useParams } from '@core-ui/redux-router';
import { COLOURS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'components/Intl';
import { batch, useDispatch } from 'react-redux';
import { MONTH_YEAR_DATE_FORMAT, RANGE_PICKER_LABELS } from '@/app/consts/app';
import { IRangeFilterPreset } from '@/app/types/common';
import {
  addMonths,
  dateToDayEndUTC,
  dateToDayStartUTC,
  dateToUTC,
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
} from '@/app/utils/dates';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  getAnalyticsExpensesList,
  setAnalyticsExpensesListLoading,
} from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesRoot/actions';
import { COST_CENTER_WIDTH, DATE_FILTER_WIDTH } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/consts';
import { IAnalyticsFiltersState } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/reducer';
import { getClientArticlesPlanFact } from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/actions';
import { setAnalyticsPlanFactLoading } from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/actions';
import { ANALYTICS_INNER_TABS } from '@/pages/analytics/AnalyticsInner/consts';
import { ANALYTICS_PATHS } from '@/pages/analytics/routes';
import { AnalyticsPathParams } from '@/pages/analytics/types';
import { setTextFilter } from './actions';
import selector from './selector';

const containerStyles: SxProps<Theme> = {
  paddingLeft: 6,
  paddingTop: 6,
  background: COLOURS.HUE_COCONUT.HUE_8,
};

const AnalyticsFilter: FC = () => {
  const { filters, costCentersOptions } = useAppSelector(selector);
  const { analyticsInnerTab } = useParams<AnalyticsPathParams>(ANALYTICS_PATHS);

  const dispatch = useDispatch();
  const intl = useIntl();

  const setLoadingCallback = useCallback(() => {
    if (analyticsInnerTab === ANALYTICS_INNER_TABS.EXPENSES) {
      dispatch(setAnalyticsExpensesListLoading(true));
    } else {
      dispatch(setAnalyticsPlanFactLoading(true));
    }
  }, [setAnalyticsExpensesListLoading]);

  const handleFilterChange = useFilter<IAnalyticsFiltersState>({
    getList: analyticsInnerTab === ANALYTICS_INNER_TABS.EXPENSES ? getAnalyticsExpensesList : getClientArticlesPlanFact,
    setFilter: setTextFilter,
    onChange: setLoadingCallback,
  });

  const handleDateChange: RangePickerProps['onChange'] = (dates) => {
    batch(() => {
      handleFilterChange('dateFrom')(dates?.[0] ? dateToDayStartUTC(startOfMonth(dates[0])) : null);
      handleFilterChange('dateTo')(dates?.[1] ? dateToDayEndUTC(endOfMonth(dates[1])) : null);
    });
  };

  const nowDate = new Date();
  const presets: IRangeFilterPreset[] = [
    {
      label: intl.formatMessage({ id: 'label.month' }),
      value: [startOfMonth(addMonths(nowDate, -1)), endOfMonth(nowDate)],
    },
    {
      label: intl.formatMessage({ id: 'label.year' }),
      value: [startOfMonth(addMonths(nowDate, -11)), endOfMonth(nowDate)],
    },
    {
      label: intl.formatMessage({ id: 'label.current_year' }),
      value: [startOfYear(nowDate), endOfYear(nowDate)],
    },
  ];
  const pickerValue: [Nullable<Date>, Nullable<Date>] = [null, null];

  if (filters.dateFrom) {
    pickerValue[0] = dateToUTC(+filters.dateFrom);
  }

  if (filters.dateTo) {
    pickerValue[1] = dateToUTC(+filters.dateTo);
  }

  return (
    <Grid container gap={3} sx={containerStyles}>
      <Grid item width={DATE_FILTER_WIDTH}>
        <DatePicker<RangePickerProps>
          presets={presets}
          dateTimeFormat={MONTH_YEAR_DATE_FORMAT}
          value={pickerValue}
          onChange={handleDateChange}
          label={RANGE_PICKER_LABELS}
          openOnFocus
          size="medium"
          picker="month"
          view="rangepicker"
          data-testid="analytics_page-filters-rangepicker"
        />
      </Grid>

      {/* <Grid item width={COST_TYPE_WIDTH}> */}
      {/*  <Autocomplete */}
      {/*    options={COST_TYPE_OPTIONS} */}
      {/*    label={<FormattedMessage id="label.cost_type" />} */}
      {/*    onChange={handleFilterChange('variability')} */}
      {/*    value={filters.variability} */}
      {/*    disableClearable={false} */}
      {/*  /> */}
      {/* </Grid> */}

      <Grid item width={COST_CENTER_WIDTH}>
        <Autocomplete
          options={costCentersOptions}
          label={<FormattedMessage id="cost.center" />}
          onChange={handleFilterChange('costCenters')}
          value={filters.costCenters}
          multiple
          limitTags={1}
          disableClearable={false}
        />
      </Grid>
    </Grid>
  );
};

export default AnalyticsFilter;
