import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import {
  PLAN_FACT_TABLE_HEADER_CELL_HEIGHT,
  PLAN_FACT_TABLE_MIN_MONTH_CELL_WIDTH,
  PLAN_FACT_TABLE_MIN_NAME_CELL_WIDTH,
} from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/components/AnalyticsPlanFactClientArticlesTable/consts';

export default (): Record<string, SxProps<Theme>> => ({
  nameCell: {
    width: PLAN_FACT_TABLE_MIN_NAME_CELL_WIDTH,
    minWidth: PLAN_FACT_TABLE_MIN_NAME_CELL_WIDTH,
    maxWidth: PLAN_FACT_TABLE_MIN_NAME_CELL_WIDTH,
    left: 0,
    zIndex: 3,
  },

  monthCell: {
    width: PLAN_FACT_TABLE_MIN_MONTH_CELL_WIDTH,
    minWidth: PLAN_FACT_TABLE_MIN_MONTH_CELL_WIDTH,
  },

  placeholderCell: {
    left: 0,
    zIndex: 3,
    top: PLAN_FACT_TABLE_HEADER_CELL_HEIGHT,
    background: COLOURS.HUE_COCONUT.HUE_16,
    borderRight: `1px solid ${COLOURS.HUE_COCONUT.HUE_50}`,
    borderBottom: `1px solid ${COLOURS.HUE_COCONUT.HUE_50}`,

    // TODO: всё что ниже в стилях этой ячейки хочется вынести в константу и затем в корку
    // overflowY: 'clip',
    // '&:after': {
    //   content: '""',
    //   position: 'absolute',
    //   top: -1,
    //   bottom: -1,
    //   right: 0,
    //   width: '100%',
    //   boxShadow: 'rgba(0, 0, 0, 0.15) 4px 0px 4px 0px',
    //   pointerEvents: 'none',
    //   touchEvents: 'none',
    // },
    //
    // [`.${EXPENSES_TABLE_SHOW_CELL_RIGHT_SHADOW_CLASS} &:after`]: {
    //   animation: `showShadow .3s ${TRANSITION_TIMING_FUNCTION} forwards`,
    // },
    //
    // [`.${EXPENSES_TABLE_HIDE_CELL_RIGHT_SHADOW_CLASS} &:after`]: {
    //   animation: `hideShadow .3s ${TRANSITION_TIMING_FUNCTION} forwards`,
    // },
    //
    // '@keyframes showShadow': {
    //   '0%': {
    //     opacity: 0,
    //   },
    //
    //   '100%': {
    //     opacity: 1,
    //   },
    // },
    //
    // '@keyframes hideShadow': {
    //   '0%': {
    //     opacity: 1,
    //   },
    //
    //   '100%': {
    //     opacity: 0,
    //   },
    // },
  },

  planFactDiffCell: {
    top: PLAN_FACT_TABLE_HEADER_CELL_HEIGHT,
    background: COLOURS.HUE_COCONUT.HUE_16,
    borderRight: `1px solid ${COLOURS.HUE_COCONUT.HUE_50}`,
    borderBottom: `1px solid ${COLOURS.HUE_COCONUT.HUE_50}`,
  },
});
