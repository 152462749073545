import { createSelector } from 'reselect';
import State from '@/app/types/state';
import { formatTime, getTimestampInMs } from '@/app/utils/dates';
import { getAmountFormatter } from '@/dictionary/selector';
import { getAnalyticsFiltersSelectedMonths } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { IMonthTotal } from '@/pages/analytics/AnalyticsInner/types';

const getAnalyticsByCostArticle = (state: State) => state.analytics.analyticsInner.expenses.clientArticles;

export default createSelector(
  [getAnalyticsByCostArticle, getAnalyticsFiltersSelectedMonths, getAmountFormatter],
  (analyticsCostArticlesList, selectedMonths, amountFormatter) => {
    const items: IMonthTotal[] = (
      analyticsCostArticlesList.value?.map(
        (costArticle) =>
          costArticle.price_per_month?.map((item) => {
            return {
              monthName: formatTime(getTimestampInMs(item.start_month), { dateFormat: 'MMMM-YY' }),
              total: item.total_price,
              ...item,
            };
          }) ?? []
      ) ?? []
    ).flat();

    return {
      items,
      selectedMonths,
      amountFormatter,
    };
  }
);
