import { COLOURS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { useEffect, useRef } from 'react';
import { getRoundedNumber } from '@/app/utils/app';
import { useAppSelector } from '@/hooks/useAppSelector';
import { IMonthTotal } from '@/pages/analytics/AnalyticsInner/types';
import { BAR_GAP, BAR_HEIGHT, GRAPH_MARGINS, Y_AXIS_TICKS } from './consts';
import * as d3 from './d3';
import { scaleBand } from './scaleBand';
import selector from './selector';
import { getBarsByMonth, getBarWidths, getExpensesBarPath } from './utils';

const containerStyles: SxProps<Theme> = {
  width: '100%',
};

const AnalyticsExpensesClientArticlesMonthGraph = () => {
  const { items, selectedMonths, amountFormatter } = useAppSelector(selector);
  const rootRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    rootRef.current!.innerHTML = '';
    const barsByMonth = getBarsByMonth(selectedMonths, items);

    if (!barsByMonth || !barsByMonth.length) {
      return;
    }

    const barWidths = getBarWidths(selectedMonths);
    const maxBarHeight = d3.max(barsByMonth, (bar) => bar.total) ?? 0;

    const graphContentWidth = (BAR_GAP + barWidths[barsByMonth.length - 1]) * barsByMonth.length;
    const offsetLeft = GRAPH_MARGINS.left;

    // create graph field
    const svg = d3
      .select(rootRef.current!)
      .append('svg')
      .attr('height', BAR_HEIGHT + GRAPH_MARGINS.top + GRAPH_MARGINS.bottom)
      .attr('width', graphContentWidth + GRAPH_MARGINS.left + GRAPH_MARGINS.right);

    const xScale = scaleBand()
      .domain(barsByMonth.map((bar: IMonthTotal) => bar.monthName))
      .range([0, graphContentWidth])
      .bandwidth(barWidths[barsByMonth.length - 1])
      .offset(BAR_GAP);

    const yScale = d3.scaleLinear().domain([0, maxBarHeight]).range([BAR_HEIGHT, 0]).nice();

    const xAxis = d3.axisBottom(xScale).ticks(barsByMonth.length);
    const yAxis = d3.axisLeft(yScale).ticks(Y_AXIS_TICKS);

    const xAxisElement = svg
      .append('g')
      .call(xAxis)
      .attr('transform', `translate(${offsetLeft}, ${BAR_HEIGHT + GRAPH_MARGINS.top + 10})`);

    xAxisElement.selectAll('path, line').remove();

    xAxisElement
      .selectAll('text')
      .attr('font-size', '14px')
      .html((_, idx) => {
        const bar = barsByMonth[idx];

        return `
        <tspan x="0" dy="0" fill="${COLOURS.HUE_COCONUT.HUE_153}" font-size="12px">${bar.monthName}</tspan>
        <tspan x="0" dy="20" fill="${
          COLOURS.HUE_COCONUT.HUE_195
        }" font-size="14px" font-family="IBM Plex Mono,monospace" font-weight="600">${amountFormatter(
          bar.total
        )}</tspan>
        `;
      });

    // TODO: чтобы ось ординат приклеить к левой части экрана нужно ещё апендить к другому элементу, в следующей итерации попробовать это сделать
    const yAxisElement = svg
      .append('g')
      .call(yAxis)
      .attr('transform', `translate(${GRAPH_MARGINS.left}, ${GRAPH_MARGINS.top})`);

    yAxisElement
      .selectAll('line')
      .attr('stroke', COLOURS.HUE_COCONUT.HUE_50)
      .attr('x1', graphContentWidth)
      .attr('x2', -GRAPH_MARGINS.left);

    yAxisElement.select('.tick line').attr('stroke', COLOURS.HUE_COCONUT.HUE_50);

    yAxisElement
      .selectAll('text')
      .attr('y', -8)
      .attr('font-size', '10px')
      .attr('user-select', 'none')
      .attr('fill', COLOURS.HUE_COCONUT.HUE_153)
      .text((num) => getRoundedNumber(num as number));

    yAxisElement.selectAll('path').remove();

    // bars
    svg
      .selectAll('.month')
      .data(barsByMonth)
      .join('path')
      .classed('month', true)
      .attr('transform', (_) => `translate(${offsetLeft}, ${GRAPH_MARGINS.top})`)
      .style('fill', COLOURS.HUE_GUAVA.HUE_120)
      .attr('d', (bar) => {
        const barValue = bar.total;
        const barWidth = xScale.bandwidth();
        const xScaleOffset = xScale(bar.monthName) ?? 0;
        const yScaleOffset = yScale(barValue) ?? 0;

        return getExpensesBarPath(barValue, barWidth, xScaleOffset, yScaleOffset);
      });
  }, [items, selectedMonths]);

  return (
    <Box ref={rootRef} sx={containerStyles} />
  );
};

export default AnalyticsExpensesClientArticlesMonthGraph;
