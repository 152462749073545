import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { ROUTES } from '@/app/consts/routes';
import { ClientCostArticlePlanFactSchema, CostClientResponseSchemaFlat } from '@/generated';
import {
  ICostArticleResponseSchema,
  IFormattedCostArticleResponseSchema,
} from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/types';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';

const flatIds = (costArticle: IFormattedCostArticleResponseSchema | ICostArticleResponseSchema): number[] => {
  if (!costArticle.children?.length) {
    return [];
  }

  const result: number[] = [];

  costArticle.children.forEach((item) => {
    if (item.children?.length) {
      result.push(...flatIds(item));
    } else {
      result.push(item.id);
    }
  });

  return result;
};

export const makeUrlToDocumentsFromExpensesMyoceanArticles = (
  boatId: Nullable<number>,
  costArticle: IFormattedCostArticleResponseSchema,
  dateFrom: Nullable<string>,
  dateTo: Nullable<string>
) => {
  return buildURL(
    `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}`,
    {
      costArticles: [costArticle.id, ...flatIds(costArticle)],
      dateFrom,
      dateTo,
    },
    { arrayFormat: 'none' }
  );
};

export const makeUrlToDocumentsFromExpensesClientArticles = (
  boatId: Nullable<number>,
  costArticle: CostClientResponseSchemaFlat,
  dateFrom: Nullable<string>,
  dateTo: Nullable<string>
) => {
  return buildURL(
    `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}`,
    {
      erpCategories: [costArticle.code],
      dateFrom,
      dateTo,
    },
    { arrayFormat: 'none' }
  );
};

export const makeUrlToDocumentsFromPlanFactClientArticles = (
  boatId: Nullable<number>,
  costArticleCode: string,
  dateFrom?: Nullable<string>,
  dateTo?: Nullable<string>
) => {
  return buildURL(
    `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}`,
    {
      erpCategories: [costArticleCode],
      dateFrom,
      dateTo,
    },
    { arrayFormat: 'none' }
  );
};
