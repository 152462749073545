import { createSelector } from 'reselect';
import { formatTime, getTimestampInMs } from '@/app/utils/dates';
import { getAmountFormatter } from '@/dictionary/selector';
import { getAnalyticsFiltersSelectedMonths } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { getAnalyticsPlanFactClientArticlesValue } from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/selector';

export default createSelector(
  [getAnalyticsPlanFactClientArticlesValue, getAnalyticsFiltersSelectedMonths, getAmountFormatter],
  (planFactValue, selectedMonths, amountFormatted) => {
    const items = planFactValue?.plan_fact_by_month?.map((item) => ({
      ...item,
      name: formatTime(getTimestampInMs(item.start_month), { dateFormat: 'MMMM-YY' }),
    }));

    return {
      items,
      selectedMonths,
      amountFormatted,
    };
  }
);
