import TableBody from '@mui/material/TableBody';
import { useAppSelector } from '@/hooks/useAppSelector';
import React from 'react';
import { AnalyticsPlanFactClientArticlesTableRow } from '..';
import selector from './selector';

const AnalyticsPlanFactClientArticlesTableBody = () => {
  const { items, boatId, selectedMonths, filters, amountFormatter } = useAppSelector(selector);

  return (
    <TableBody>
      {items?.map((costArticle) => (
        <AnalyticsPlanFactClientArticlesTableRow
          key={costArticle.cost_article_clients_code}
          costArticle={costArticle}
          boatId={boatId}
          monthInterval={selectedMonths}
          dateFrom={filters.dateFrom}
          dateTo={filters.dateTo}
          amountFormatter={amountFormatter}
        />
      ))}
    </TableBody>
  );
};

export default AnalyticsPlanFactClientArticlesTableBody;
