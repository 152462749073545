import { createSelector } from 'reselect';
import { formatTime, getTimestampInMs } from '@/app/utils/dates';
import { getAmountFormatter } from '@/dictionary/selector';
import { getAnalyticsMyoceanArticlesList } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/selector';
import { getAnalyticsFiltersSelectedMonths } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { IMonthTotal } from '@/pages/analytics/AnalyticsInner/types';

export default createSelector(
  [getAnalyticsMyoceanArticlesList, getAnalyticsFiltersSelectedMonths, getAmountFormatter],
  (analyticsCostArticlesList, selectedMonths, amountFormatter) => {
    const items: IMonthTotal[] = (
      analyticsCostArticlesList.value?.map((costArticle) => {
        // нам нужны только тоталы родительских артикулов т.к. они уже включают в себя сумму тоталов их детей
        if (!costArticle.isRootArticle) {
          return [];
        }

        return (
          costArticle.price_per_month?.map((item) => {
            return {
              monthName: formatTime(getTimestampInMs(item.start_month), { dateFormat: 'MMMM-YY' }),
              total: item.total_price,
            };
          }) ?? []
        );
      }) ?? []
    ).flat();

    return {
      items,
      selectedMonths,
      amountFormatter,
    };
  }
);
